import React, { useContext, useEffect } from "react";
import { FirebaseAuthContext } from "../context/FirebaseAuthContext";
import { navigate } from "gatsby-plugin-intl";
import { BlockUIContext } from "../context/BlockUIContext";
import firebase from "gatsby-plugin-firebase";

import Header from "../components/common/Header";
import Bottom from "../components/common/Bottom";
import tw from "twin.macro";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SecureRoute = ({ children, path, roles, title }) => {
    const { loading, isAuthenticated, isInRole, setUserData } = useContext(FirebaseAuthContext);
    const { setBlocking } = useContext(BlockUIContext);

    // Mostrem o no Loading Screen
    useEffect(() => {
        setBlocking(loading);

        // En cas que l'autenticació no sigui correcta
        if (!loading && !isAuthenticated) {
            navigate(path);
        }

        // En caso que la autenticación sea correcta
        // y hayamos terminado de cargar
        if (!loading && isAuthenticated) {
            if (!isInRole(roles)) {
                console.log("No tenemos roles. Cerramos sesión");
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        setUserData(undefined);
                    });
            }
        }
    }, [loading, path, setBlocking, isAuthenticated, roles, isInRole, setUserData]);

    return (
        <>
            <ToastContainer />
            {!loading && isAuthenticated && (
                <>
                    {/** Header de la aplicación */}
                    <Header title={title || ""} />

                    {/** Main de la aplicación */}
                    <main css={[tw`-mt-32`]}>
                        <div css={[tw`max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8`]}>
                            <div css={[tw`bg-white rounded-lg shadow px-5 py-6 sm:px-6`]}>
                                <div
                                    css={[
                                        tw`border-4 border-dashed border-gray-200 rounded-lg p-5`,
                                    ]}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </main>

                    <Bottom />
                </>
            )}
        </>
    );
};
export default SecureRoute;
