import React from "react";
import tw from "twin.macro";

const Bottom = () => {
    return (
        <>
            <div css={[tw`mx-auto pb-12 pt-4 sm:px-6 lg:px-8 bg-quaternary`]}>
                <div css={[tw`mx-auto w-full max-w-sm lg:w-96 bottom-5 mt-10`]}>
                    <div
                        css={[
                            tw`mt-1 text-gray-600 text-center text-sm font-montserrat font-light text-white`,
                        ]}
                    >
                        Copyright &copy; {new Date().getFullYear()} - Ajuntament de Mont-roig del
                        Camp
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bottom;
